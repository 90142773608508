
@tailwind base;
@tailwind components;
@tailwind utilities;


/* Question 1 */
span[id^='radio-choice-71802-3']:not(#radio-choice-71802-3\|LA31993-1) {
    text-decoration: underline;
}

/* Question 2 */
span[id^='radio-choice-96778-6']:not(#radio-choice-96778-6\|LA9-3) {
    text-decoration: underline;
}

/* Question 3 */
span[id^='radio-choice-88122-7']:not(#radio-choice-88122-7\|LA28398-8) {
    text-decoration: underline;
}

/* Question 4 */
span[id^='radio-choice-88123-5']:not(#radio-choice-88123-5\|LA28398-8) {
    text-decoration: underline;
}

/* Question 5 */
#radio-choice-93030-5\|LA33-6 {
    text-decoration: underline;
}


/* Question 6 */
span[id^='radio-choice-96779-4']:not(#radio-choice-96779-4\|LA32-8) {
    text-decoration: underline;
}

/* Question 11 */
span[id^='radio-choice-76513-1']:not(#radio-choice-76513-1\|LA31980-8) {
    text-decoration: underline;
}

/* Question 12 */
span[id^='radio-choice-96780-2']:not(#radio-choice-96780-2\|LA31983-2) {
    text-decoration: underline;
}

/* Question 13 */
#radio-choice-96781-0\|LA31979-0,
#radio-choice-96781-0\|LA31978-2 
{
    text-decoration: underline;
}

/* Question 14 */
#radio-choice-93159-2\|LA10044-8
#radio-choice-93159-2\|LA9933-8{
    text-decoration: underline;
}


/* Question 15 */
#radio-choice-97027-7\|LA33-6 {
    text-decoration: underline;
}

/* Question 16 */
#radio-choice-96782-8\|LA33-6 {
    text-decoration: underline;
}

/* Question 19 */
span[id^='radio-choice-68517-2']:not(#radio-choice-68517-2\|LA6270-8) {
    text-decoration: underline;
}

/* Question 20 */
span[id^='radio-choice-96842-0']:not(#radio-choice-96842-0\|LA6270-8) {
    text-decoration: underline;
}


/* Question 21 */
span[id^='radio-choice-95530-2']:not(#radio-choice-95530-2\|LA6270-8) {
    text-decoration: underline;
}

/* Question 22 */
span[id^='radio-choice-68524-8']:not(#radio-choice-68524-8\|LA6270-8) {
    text-decoration: underline;
}

/* Question 24 */
span[id^='radio-choice-93038-8']:not(#radio-choice-93038-8\|LA6568-5) {
    text-decoration: underline;
}

/* Question 25 */
#radio-choice-69858-9\|LA33-6{
    text-decoration: underline;
}

/* Question 26 */
#radio-choice-69861-3\|LA33-6{
    text-decoration: underline;
}